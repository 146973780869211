import { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useGetOrderMediaConfig } from "../views/PlaceOrderTest/queryHooks";

type Props = {
  modalHeader: string;
  inputPlaceHolder: string;
  isOpen: boolean;
  onSave: (input: string, secondaryInput?: string) => void;
  onClose: () => void;
  cancelBtnText?: string;
  submitBtnText?: string;
  showActionText?: boolean;

  modalSecondaryHeader?: string;
  displaySecondaryInput?: boolean;
  secondaryInput?: string;
  secondaryInputPlaceHolder?: string;
};

const TextInputModal = (props: Props) => {
  const [text, setText] = useState("");
  const [secondaryText, setSecondaryText] = useState(
    props.secondaryInput || ""
  );

  const { data: configData } = useGetOrderMediaConfig();

  const handleSave = () => {
    props.onSave(text, secondaryText.trim());
    props.onClose();
    setText("");
  };

  const handleCloseButtonClick = () => {
    props.onClose();
    setText("");
  };

  const minCharLength = Number(JSON.parse(configData?.min_reason_length));

  return (
    <Modal
      returnFocusOnClose={false}
      closeOnOverlayClick={false}
      isOpen={props.isOpen}
      onClose={handleCloseButtonClick}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={1}>
          {props.modalHeader}
          {props.showActionText && (
            <Text fontStyle={"italic"} fontSize={"xs"}>
              This Action will be Recorded.
            </Text>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingTop={0}>
          <Textarea
            placeholder={props.inputPlaceHolder}
            onChange={(e) => setText(e.target.value)}
          />
        </ModalBody>
        {props.modalSecondaryHeader && (
          <ModalHeader paddingBottom={1}>{props.modalSecondaryHeader}</ModalHeader>
        )}
        {props.displaySecondaryInput && (
          <ModalBody paddingTop={0}>
            <Textarea
              value={secondaryText}
              placeholder={props.secondaryInputPlaceHolder}
              onChange={(e) => setSecondaryText(e.target.value)}
            />
          </ModalBody>
        )}
        <ModalFooter>
          <Button onClick={handleCloseButtonClick} mr={3}>
            {props.cancelBtnText || "Cancel"}
          </Button>
          <Button
            isDisabled={
              text.length < minCharLength &&
              secondaryText === props.secondaryInput
            }
            colorScheme="blue"
            onClick={handleSave}
          >
            {props.submitBtnText || "Confirm"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TextInputModal;

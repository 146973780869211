import GaraazGenericAlert from "./GaraazGenericAlert";
import { FcInfo } from "react-icons/fc";

interface GaraazInfoDialogueProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  leftBtnText: string;
  submitBtnText: string;
  onSubmitBtn: () => void;
  alertBodyText: any;
  alertGenericHeaderText: any;
}

const GaraazInfoDialogue: React.FC<GaraazInfoDialogueProps> = (props) => {
  return (
    <GaraazGenericAlert
      isOpen={props.isOpen}
      onOpen={props.onOpen}
      onClose={props.onClose}
      leftBtnText={props.leftBtnText}
      submitBtnText={props.submitBtnText}
      onSubmitBtn={props.onSubmitBtn}
      alertBodyText={props.alertBodyText}
      alertGenericHeaderText={props.alertGenericHeaderText}
      color={"#026ee5"}
      icon={<FcInfo fontSize={"5rem"}></FcInfo>}
    ></GaraazGenericAlert>
  );
};

export default GaraazInfoDialogue;

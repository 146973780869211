const CloudworxIframe = () => {
  return (
    <iframe
      src="https://garaaz.cloudworx.ai"
      allow="fullscreen"
      width={"100%"}
      height={"100%"}
    ></iframe>
  );
};

export default CloudworxIframe;

import {
  Box,
  Button,
  Icon,
  IconButton,
  Image,
  ListItem,
  UnorderedList,
  useDisclosure,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Radio,
  Input,
} from "@chakra-ui/react";
import endpoints from "../../../utils/endpoints";
import { IoMdClose } from "react-icons/io";
import { publicAxios as axios } from "../../../config/axios";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import services from "../../Orders/services";
import services1 from "../services";
import useNotification from "../../../utils/useNotification";
import { FaExternalLinkAlt } from "react-icons/fa";
import { GoAlert } from "react-icons/go";

import useSound from "use-sound";
import cart from "./sounds/cart.mp3";
import puzzleIcon from "./puzzle.png";
import constants from "../../../utils/constants";
import { MdModeEdit } from "react-icons/md";
import { VscVerified } from "react-icons/vsc";
import {} from "react-icons/md";

import GaraazInfoDialogue from "../../../components/GaraazInfoDialogue";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Home } from "@mui/icons-material";

const CarIframe = () => {
  const [playSound] = useSound(cart);
  const [displayBox, setDisplayBox] = useState("none");
  const toast = useNotification();
  const [activePartIndex, setActivePartIndex] = useState(-1);
  const [partsData, setPartsData] = useState<any[]>([]);
  const { state } = useLocation();
  const selectedCarData = state?.carData;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [editPartNumber, setEditPartNumber] = useState("");
  const [selectedCompatiblePart, setSelectedCompatiblePart] = useState("");
  const {
    isOpen: isCompatiblePartsOpen,
    onOpen: onCompatiblePartsOpen,
    onClose: onCompatiblePartsClose,
  } = useDisclosure();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isUnverifyDialogueOpen,
    onClose: onUnVerifyDialogueClose,
    onOpen: onUnVerifyDialogueOpen,
  } = useDisclosure();

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  useEffect(() => {
    if (displayBox === "block") {
      document.getElementById("part-qty")?.focus();
    }
  }, [displayBox]);
  const handleMessage = async (event: any) => {
    if (event.data.type === "polyHotspotClicked") {
      handlePolyHotspotClicked(event.data.hotspotInfo);
      return;
    }

    if (event.data.type === "internalViewClicked") {
      handleInternalViewClick();
      return;
    }

    if (event.data.type === "3DHotspotClicked") {
      handle3DHotspotClicked(event.data.hotspotTitle);
      return;
    }

    if (event.data.type === "cloudworxClicked") {
      handleCloudworxClick();
      return;
    }
  };

  function handle3DHotspotClicked(hotspotTitle: any) {
    navigate(`/engine/3-d?model=${hotspotTitle}`);
  }

  async function handlePolyHotspotClicked(hotspotInfo: any) {
    setPartsData((prevPartsData) => {
      const partIndex = prevPartsData.findIndex(
        (part) => part.UPN === hotspotInfo.title
      );
      if (partIndex === -1) {
        fetchCarPartDetailsAndUpdate(hotspotInfo.title, prevPartsData);
      } else {
        setActivePartIndex(partIndex);
        setDisplayBox("block");
      }
      return prevPartsData;
    });
  }
  const fetchCarPartDetailsAndUpdate = async (
    partCategory: any,
    prevPartsData: any[]
  ) => {
    const carPartDetails = await fetchCarPartDetails(partCategory);
    setPartsData([
      ...prevPartsData,
      {
        compatibleParts: carPartDetails,
        quantity: 1,
        o2vrUPN: partCategory,
        selectedPart: carPartDetails[0],
      },
    ]);
    setActivePartIndex(prevPartsData.length);
    setDisplayBox("block");
  };
  async function fetchCarPartDetails(partCategory: any) {
    try {
      const response = await axios({
        url: `${endpoints.getCarPartDetails(
          selectedCarData._id
        )}?UPN=${partCategory}`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("auth_orca"),
        },
      });

      return response.data;
    } catch (err) {}
  }

  async function handleAddToCart(
    legacyPartMasterId: any,
    orderedQuantity: any
  ) {
    try {
      if (!state.orderId || !state.orderNo) {
        toast({
          status: "error",
          description: "Please select an order",
          duration: 2000,
        });
        return;
      }
      const updatedOrder = await services.insertOrUpdatePart(state.orderId, {
        partId: legacyPartMasterId,
        orderedQuantity: orderedQuantity,
      });
      setDisplayBox("none");
      toast({
        status: "success",
        description: `Successfully added part to order : ${state.orderNo}`,
        duration: 2000,
      });
    } catch (error) {
      toast({
        status: "error",
        description: `${error}`,
        duration: 2000,
      });
    }
  }
  async function handleInternalViewClick() {
    navigate(`${pathname.replace("o2vr", "p2vr")}`);
  }

  async function handleCloudworxClick() {
    navigate(`${pathname.replace("o2vr", "cloudworx")}`);
  }

  async function handleAddEditPartNumber() {
    try {
      const partIdentificationRequestPaylod: { [key: string]: any } = {
        name: "UPSERT_CAR_REQUEST",
        generation: selectedCarData.generation,
        carName: selectedCarData.name,
        description: selectedCarData.description,
        editSpareParts: [
          {
            UPN: partsData[activePartIndex].o2vrUPN,
            partNumber: editPartNumber,
          },
        ],
      };

      const response = await services1.addCarSparePartRequest(
        partIdentificationRequestPaylod
      );

      toast({
        description: "Part Identification Request submitted successfully",
        status: "success",
      });
      setEditPartNumber("");
      setIsEditingMode(false);
      setDisplayBox("none");
    } catch (error: any) {
      toast({
        description: error.response.data.errorMsg ?? "Something Went Wrong",
        status: "error",
      });
    }
  }

  async function handleVerifyPartNumberClick() {
    try {
      const response = await services1.verifyCarPart(
        selectedCarData._id,
        partsData[activePartIndex].o2vrUPN.trim(),
        partsData[activePartIndex].number
      );
      setPartsData((prevPartsData: any) => {
        let newPartData = [...prevPartsData];
        newPartData[activePartIndex].isVerified = true;
        return newPartData;
      });
      toast({ description: "Part Verified Successfully", status: "success" });
      onClose();
    } catch (error: any) {
      toast({
        description: error.response.data.errorMsg ?? "Something Went Wrong",
        status: "error",
      });
    }
  }
  async function handleUnVerifyPartNumberClick() {
    try {
      const response = await services1.unVerifyCarPart(
        selectedCarData._id,
        partsData[activePartIndex].o2vrUPN.trim(),
        partsData[activePartIndex].number
      );
      setPartsData((prevPartsData: any) => {
        let newPartData = [...prevPartsData];
        newPartData[activePartIndex].isVerified = false;
        return newPartData;
      });
      toast({ description: "Part Unverified Successfully", status: "success" });
      onUnVerifyDialogueClose();
    } catch (error: any) {
      toast({
        description: error.response.data.errorMsg ?? "Something Went Wrong",
        status: "error",
      });
    }
  }

  return (
    <Box height={"100%"}>
      <iframe
        src={`/${selectedCarData.o2vrIndexFile}`}
        width={"100%"}
        height={"100%"}
        style={{ margin: "auto" }}
      ></iframe>

      <NavLink to={"/"}>
        <IconButton
          fontSize={"xs"}
          size={"sm"}
          aria-label={""}
          as={Home}
          position={"absolute"}
          left={3}
          right={"0"}
          bottom={1.5}
          cursor={"pointer"}
          bgColor={"transparent"}
          color={"#666666"}
          _hover={{ bgColor: "transparent", color: "black", boxShadow: "none" }}
        ></IconButton>
      </NavLink>

      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          margin: "auto",
          minWidth: "350px",
          width: "max-content",
          height: "fit-content",
          backgroundColor: "#E6E5DB",
          display: displayBox,
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <div
          style={{
            padding: "10px",
            backgroundColor: "#BFBFBF",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 style={{ margin: 0, color: "#414141" }}>Part Information</h3>

            {partsData[activePartIndex]?._id &&
              !partsData[activePartIndex]?.isVerified &&
              !isEditingMode && (
                <Icon
                  as={VerifiedOutlinedIcon}
                  fontSize={"2xl"}
                  ml={1}
                  onClick={onOpen}
                  cursor={"pointer"}
                ></Icon>
              )}
            {partsData[activePartIndex]?._id &&
              partsData[activePartIndex]?.isVerified &&
              !isEditingMode && (
                <Icon
                  as={VerifiedIcon}
                  fontSize={"2xl"}
                  ml={1}
                  onClick={onUnVerifyDialogueOpen}
                  color={"green"}
                  cursor={"pointer"}
                ></Icon>
              )}
            {partsData[activePartIndex]?._id &&
              !partsData[activePartIndex]?.isVerified &&
              !isEditingMode && (
                <GaraazInfoDialogue
                  onOpen={onOpen}
                  onClose={onClose}
                  isOpen={isOpen}
                  onSubmitBtn={handleVerifyPartNumberClick}
                  alertBodyText={
                    "Have you checked the part number? Please confirm before proceeding."
                  }
                  leftBtnText={"Cancel"}
                  submitBtnText={"Confirm"}
                  alertGenericHeaderText={"Verify Part Number"}
                ></GaraazInfoDialogue>
              )}

            {partsData[activePartIndex]?._id &&
              partsData[activePartIndex]?.isVerified &&
              !isEditingMode && (
                <GaraazInfoDialogue
                  onOpen={onUnVerifyDialogueOpen}
                  onClose={onUnVerifyDialogueClose}
                  isOpen={isUnverifyDialogueOpen}
                  onSubmitBtn={handleUnVerifyPartNumberClick}
                  alertBodyText={
                    "Have you checked the part number? Please confirm before proceeding."
                  }
                  leftBtnText={"Cancel"}
                  submitBtnText={"Confirm"}
                  alertGenericHeaderText={"Unverify Part Number"}
                ></GaraazInfoDialogue>
              )}
          </div>

          <IconButton
            icon={<IoMdClose></IoMdClose>}
            aria-label=""
            size={"xs"}
            cursor={"pointer"}
            onClick={() => {
              setIsEditingMode(false);
              setEditPartNumber("");
              setDisplayBox("none");
            }}
            color={"white"}
            bgColor={"#F3565D"}
          ></IconButton>
        </div>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          paddingLeft={4}
          paddingRight={4}
          paddingTop={2}
          paddingBottom={2}
        >
          <UnorderedList listStyleType={"none"} marginLeft={0}>
            <ListItem marginBottom={2}>UPN</ListItem>
            <ListItem marginBottom={2}>Part Number</ListItem>
            {!isEditingMode && <ListItem>MRP</ListItem>}
          </UnorderedList>

          <UnorderedList listStyleType={"none"} marginLeft={20}>
            <ListItem marginBottom={2}>
              {partsData[activePartIndex]?.o2vrUPN}
            </ListItem>
            <ListItem marginBottom={2}>
              <Box display={"flex"} alignItems={"center"}>
                {!isEditingMode && (
                  <span>
                    {partsData[activePartIndex]?.selectedPart?.number}
                  </span>
                )}
                {partsData[activePartIndex]?.compatibleParts.length > 1 && (
                  <Text
                    textDecoration={"underline"}
                    color={"red"}
                    ml={3}
                    onClick={() => {
                      setSelectedCompatiblePart(
                        partsData[activePartIndex]?.selectedPart._id
                      );
                      onCompatiblePartsOpen();
                    }}
                    cursor="pointer"
                  >
                    C
                  </Text>
                )}
                {partsData[activePartIndex]?.compatibleParts.length === 0 &&
                  !isEditingMode && (
                    <IconButton
                      icon={<MdModeEdit></MdModeEdit>}
                      aria-label={""}
                      size={"xs"}
                      bg={"none"}
                      color={"black"}
                      fontSize={"lg"}
                      ml={1}
                      onClick={() => {
                        setIsEditingMode(true);
                      }}
                    ></IconButton>
                  )}
                {isEditingMode && (
                  <Input
                    size={"sm"}
                    value={editPartNumber}
                    onChange={(e) => {
                      setEditPartNumber(e.target.value);
                    }}
                  ></Input>
                )}
              </Box>
            </ListItem>

            {!isEditingMode && (
              <ListItem>
                {partsData[activePartIndex]?.selectedPart?.mrp}
              </ListItem>
            )}
          </UnorderedList>
        </Box>

        {!isEditingMode && (
          <Box
            padding={"10px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <div>
              <span> Qty : </span>
              <input
                id="part-qty"
                type="text"
                style={{ width: "50px", paddingLeft: "6px" }}
                value={partsData[activePartIndex]?.quantity ?? 1}
                onChange={(e) => {
                  if (isNaN(+e.target.value)) return;
                  let newPartsData = partsData.map((item, index) => {
                    if (index === activePartIndex) {
                      return {
                        ...item,
                        quantity: +e.target.value,
                      };
                    } else {
                      return item;
                    }
                  });
                  setPartsData(newPartsData);
                }}
              ></input>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                mr={1}
                bg={"none"}
                color={"black"}
                size={"xs"}
                icon={<FaExternalLinkAlt></FaExternalLinkAlt>}
                aria-label={"link"}
                justifySelf={"flex-end"}
                onClick={() => {
                  window.open(partsData[activePartIndex].partLink, "_blank");
                }}
                _focus={{ bg: "none", boxShadow: "none" }}
              ></IconButton>

              <Button
                style={{}}
                bg={"none"}
                _hover={{
                  bg: "none",
                  boxShadow: "none",
                }}
                color={"black"}
                cursor={"pointer"}
                display={"flex"}
                justifySelf={"flex-end"}
                alignItems={"center"}
                height={"fit-content"}
                p={0}
                _focus={{ bg: "none", boxShadow: "none" }}
                isDisabled={!partsData[activePartIndex]?.selectedPart}
              >
                {" "}
                +{" "}
                <Image
                  width="20px"
                  height="20px"
                  src="https://img.icons8.com/material-rounded/24/shopping-cart.png"
                  alt="shopping-cart"
                  onClick={() => {
                    handleAddToCart(
                      partsData[activePartIndex].selectedPart.partMasterId,
                      partsData[activePartIndex].quantity
                    );
                    playSound();
                  }}
                />
              </Button>
            </div>
          </Box>
        )}
        {isEditingMode && (
          <Box display={"flex"} justifyContent={"flex-end"} padding={"10px"}>
            <Button
              size={"xs"}
              mr={2}
              onClick={() => {
                setIsEditingMode(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size={"xs"}
              onClick={handleAddEditPartNumber}
              isDisabled={editPartNumber.trim() === ""}
            >
              Confirm
            </Button>
          </Box>
        )}
      </div>
      <Modal
        isOpen={isCompatiblePartsOpen}
        onClose={onCompatiblePartsClose}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent backgroundColor={"#E6E5DB"}>
          <ModalHeader
            style={{
              padding: "10px",
              backgroundColor: "#BFBFBF",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <h3 style={{ margin: 0, color: "#414141" }}>Compatible Parts</h3>
          </ModalHeader>

          <ModalBody>
            <Table fontSize={"xs"} ml={0}>
              <Thead>
                <Tr>
                  <Th borderColor={"grey"} p={0}></Th>
                  <Th borderColor={"grey"} p={2}>
                    S. No
                  </Th>
                  <Th borderColor={"grey"} p={2}>
                    Part Number
                  </Th>
                  <Th borderColor={"grey"} p={2}>
                    Part Name
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {partsData[activePartIndex]?.compatibleParts?.map(
                  (item: any, index: number) => {
                    return (
                      <Tr
                        _hover={{ backgroundColor: "lightgrey" }}
                        cursor={"pointer"}
                        onClick={() => {
                          setSelectedCompatiblePart(item._id);
                        }}
                      >
                        <Td p={0} borderColor={"grey"}>
                          <Radio
                            borderColor={"grey"}
                            isChecked={selectedCompatiblePart === item._id}
                          ></Radio>
                        </Td>
                        <Td borderColor={"grey"} p={2}>
                          {index + 1}
                        </Td>
                        <Td borderColor={"grey"} p={2}>
                          {item?.number}
                        </Td>
                        <Td borderColor={"grey"} p={2} whiteSpace={"nowrap"}>
                          {item?.partName}
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onCompatiblePartsClose}
              mr={3}
              color={"white"}
              backgroundColor={"#F3565D"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                let newPartsData = partsData.map((partData, index) => {
                  if (index === activePartIndex) {
                    return {
                      ...partData,
                      selectedPart: partData.compatibleParts.filter(
                        (compatiblePart: any) =>
                          compatiblePart._id === selectedCompatiblePart
                      )[0],
                    };
                  } else {
                    return partData;
                  }
                });
                setPartsData(newPartsData);
                onCompatiblePartsClose();
              }}
              bgColor={"white"}
              color={"#4a5568"}
              _hover={{ bgColor: "#BFBFBF" }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CarIframe;

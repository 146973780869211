export const redirectToOrderPage = (orderNo: string | number) => { 
  const hostname =
    typeof window !== "undefined" ? window.location.hostname : "";
  const isUAT = hostname.includes("uat") || hostname === "localhost";

  const baseUrl = isUAT
    ? "https://uat.orca.garaaz.com"
    : "https://orca.garaaz.com";

  window.open(`${baseUrl}/same-part-orders?orderNo=${orderNo}`, "_blank");
};
 
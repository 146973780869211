import {
  Box,
  Image,
  Link,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { publicAxios as axios } from "../../../config/axios";
import endpoints from "../../../utils/endpoints";
import PageScaffold from "../../../components/PageScaffold";
import { useLocation, useNavigate } from "react-router-dom";

const CarList = () => {
  const [cars, setCars] = useState<any[]>([]);
  const [filteredCars, setFilteredCars] = useState<any[]>([]);
  const navigate = useNavigate();
  const { pathname, state, search } = useLocation();
  const generationParam = new URLSearchParams(search).get("generation");
  const [filterColumnValues, setFilterColumnValues] = useState({
    engine: "",
    manufacturingPeriod: "",
    chassisType: "",
  });

  const [uniqueValue, setUniqueValues] = useState<{
    manufacturingPeriod: any[];
    engine: any[];
    chassisType: any[];
  }>({
    manufacturingPeriod: [],
    engine: [],
    chassisType: [],
  });

  useEffect(() => {
    fetchCarData();
  }, []);

  useEffect(() => {
    let uniqueValueTemp = {
      manufacturingPeriod: new Set<any>(),
      engine: new Set<any>(),
      chassisType: new Set<any>(),
    };
    cars.forEach((car: any) => {
      uniqueValueTemp.engine.add(car.engine);
      uniqueValueTemp.manufacturingPeriod.add(car.manufacturingPeriod);
      uniqueValueTemp.chassisType.add(car.chassisType);
    });

    setUniqueValues({
      manufacturingPeriod: Array.from(uniqueValueTemp.manufacturingPeriod),
      engine: Array.from(uniqueValueTemp.engine),
      chassisType: Array.from(uniqueValueTemp.chassisType),
    });
  }, [cars]);

  useEffect(() => {
    const filteredCarsTemp = cars.filter((car: any) => {
      let engineFiltercondition = false;
      let manufacturingPeriodCondition = false;
      let chassisTypeCondition = false;

      if (
        car.engine === filterColumnValues.engine ||
        filterColumnValues.engine === ""
      )
        engineFiltercondition = true;

      if (
        car.manufacturingPeriod === filterColumnValues.manufacturingPeriod ||
        filterColumnValues.manufacturingPeriod === ""
      )
        manufacturingPeriodCondition = true;

      if (
        car.chassisType === filterColumnValues.chassisType ||
        filterColumnValues.chassisType === ""
      )
        chassisTypeCondition = true;

      return (
        engineFiltercondition &&
        manufacturingPeriodCondition &&
        chassisTypeCondition
      );
    });
    setFilteredCars(filteredCarsTemp);
  }, [filterColumnValues]);

  async function fetchCarData() {
    const response = await axios({
      method: "GET",
      url: `${endpoints.getCars}?generation=${generationParam}&isActive=true`,
      headers: {
        Authorization: localStorage.getItem("auth_orca"),
      },
    });

    setCars(response.data);
    setFilteredCars(response.data);
  }

  function handleHeaderValueCLick(headerName: string, option: string) {
    setFilterColumnValues((previousFilterColumnValue: any) => {
      return {
        ...previousFilterColumnValue,
        [`${headerName}`]: option,
      };
    });
  }

  function handleCarVariantClick(car: any) {
    if (car._id === "66aecc1a022108848a20445d") {
      navigate(`${pathname}/${car._id}/cloudworx`);
    } else {
      navigate(`${pathname}/${car._id}/o2vr`, {
        state: {
          ...state,
          carData: car,
        },
      });
    }
  }

  return (
    <PageScaffold isHeaderVisible={false} title="Cars">
      <Box
        p={10}
        display={"flex"}
        flexDirection={"column"}
        backgroundColor={"white"}
        minHeight={"100vh"}
      >
        <Box display={"flex"}>
          <Image src={cars[0]?.preview} mr={2}></Image>

          <Text fontWeight={"600"} fontSize={"4xl"} color={"rgb(33,37,41)"}>
            {generationParam}
          </Text>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <TableContainer mt={10} width={"100%"}>
            <Table fontSize={"small"}>
              <Thead>
                <Tr
                  backgroundColor={"#f5f5f5"}
                  borderTop={"1px solid #dee2e6"}
                  borderBottom={"1px solid #dee2e6"}
                >
                  <Th
                    color={"black"}
                    borderRight={"1px solid #dee2e6"}
                    borderLeft={"1px solid #dee2e6"}
                  >
                    Variant
                  </Th>
                  <Th
                    color={"black"}
                    borderRight={"1px solid #dee2e6"}
                    borderLeft={"1px solid #dee2e6"}
                  >
                    Fuel Type
                  </Th>
                  <Th
                    color={"black"}
                    borderRight={"1px solid #dee2e6"}
                    borderLeft={"1px solid #dee2e6"}
                  >
                    Engine Displacement
                  </Th>

                  <Th p={2} color={"black"} borderRight={"1px solid #dee2e6"}>
                    {" "}
                    <Select
                      defaultValue={filterColumnValues.manufacturingPeriod}
                      bgColor={"white"}
                      onChange={(e) => {
                        handleHeaderValueCLick(
                          "manufacturingPeriod",
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Manufacturing Period</option>;
                      {uniqueValue.manufacturingPeriod.map(
                        (year: any, index: any) => {
                          return (
                            <option value={year} key={`${year}-${index}`}>
                              {year}
                            </option>
                          );
                        }
                      )}
                    </Select>
                  </Th>
                  <Th color={"black"} borderRight={"1px solid #dee2e6"} p={2}>
                    <Select
                      defaultValue={filterColumnValues.engine}
                      bgColor={"white"}
                      onChange={(e) => {
                        handleHeaderValueCLick("engine", e.target.value);
                      }}
                    >
                      <option value="">Engine</option>;
                      {uniqueValue.engine.map((engine: any, index: any) => {
                        return (
                          <option value={engine} key={`${engine}-${index}`}>
                            {engine}
                          </option>
                        );
                      })}
                    </Select>
                  </Th>
                  <Th color={"black"} borderRight={"1px solid #dee2e6"}>
                    Description
                  </Th>
                  <Th color={"black"} borderRight={"1px solid #dee2e6"}>
                    <Select
                      defaultValue={filterColumnValues.chassisType}
                      bgColor={"white"}
                      onChange={(e) => {
                        handleHeaderValueCLick("chassisType", e.target.value);
                      }}
                    >
                      <option value="">Chassis Type</option>;
                      {uniqueValue.chassisType.map(
                        (chassis: any, index: any) => {
                          return (
                            <option value={chassis} key={`${chassis}-${index}`}>
                              {chassis}
                            </option>
                          );
                        }
                      )}
                    </Select>
                  </Th>
                  <Th color={"black"} borderRight={"1px solid #dee2e6"}>
                    V No.
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCars.map((car: any, index) => (
                  <Tr
                    key={car._id}
                    _hover={{ backgroundColor: "#ececec" }}
                    cursor={"pointer"}
                  >
                    <Td
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                      borderLeft={"1px solid #dee2e6"}
                    >
                      {" "}
                      <Text
                        fontSize={"small"}
                        color={"#017Bfe"}
                        _hover={{ textDecoration: "underline" }}
                        onClick={() => {
                          handleCarVariantClick(car);
                        }}
                      >
                        {car.variantName}
                      </Text>{" "}
                    </Td>
                    <Td
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                    >
                      {car.fuelType}
                    </Td>
                    <Td
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                    >
                      {car.engineDisplacement}
                    </Td>

                    <Td
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                    >
                      {car.manufacturingPeriod}
                    </Td>
                    <Td
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                    >
                      {car.engine}
                    </Td>
                    <Td
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                    >
                      {car.description}
                    </Td>
                    <Td
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                    >
                      {car.chassisType}
                    </Td>
                    <Td
                      p={2}
                      paddingLeft={3}
                      borderBottomColor={"#Dee2e6"}
                      borderRight={"1px solid #dee2e6"}
                    >
                      {car.vNo}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </PageScaffold>
  );
};

export default CarList;

import {
  Popover,
  PopoverContent,
  HStack,
  IconButton,
  ListItem,
  UnorderedList,
  Tooltip,
  Box,
  Text,
} from "@chakra-ui/react";
import { GrDownload } from "react-icons/gr";
import GarrazCopyText from "./GaraazCopyText";
import services from "../services";
import React, { useCallback } from "react";
import { useGetAllSuppliers } from "../queryHooks";

type InvoicePopoverProps = {
  children?: React.ReactNode;
  Invoices: any[];
  copierKeys: any[];
  Challans: any[];
};

const InvoicePopver: React.FC<InvoicePopoverProps> = ({
  Invoices,
  children,
  copierKeys,
  Challans,
}) => {
  const { data: allSuppliers } = useGetAllSuppliers();
  const getTitleCaseFromCamelCase = (word: string) => {
    const result = word.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const getSupplierBusinessName = useCallback(
    (supplierId: string) => {
      const supplier = allSuppliers?.find(
        (supplier: any) => supplier._id === supplierId
      );
      return supplier ? supplier.businessName : "";
    },
    [allSuppliers, Invoices]
  );

  async function downloadInvoiceForOrder(invoiceMediaId: any) {
    try {
      const mediaResponse = await services.getMediaData(invoiceMediaId);
      window.open(mediaResponse.path, '_blank')
    } catch (error) {
      alert("Invoice is not available");
    }
  }

  const excludedKeys = [
    "title",
    "invoiceMediaId",
    "supplierId",
    "challanMediaId",
  ];

  return (
    <Popover returnFocusOnClose={false} trigger="hover">
      {children}

      <PopoverContent style={{ padding: "10px", height: "100%" }}>
        <HStack justifyContent={"space-between"} mb="2">
          <Text color={"#4A5567"} fontSize={"lg"} fontWeight={"bold"}>
            Invoice Info
          </Text>
        </HStack>

        {Invoices.every((item) => !item?.invoiceNo) && (
          <Text mb={2} fontSize={"sm"}>
            No Invoice Available
          </Text>
        )}

        <Box textColor={"#4A5567"}>
          <UnorderedList fontSize={"sm"}>
            {Invoices?.map((item: any, index: number) =>
              item?.invoiceNo ? (
                <ListItem key={index}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    {" "}
                    <Text fontWeight={"bold"}>
                      {item?.title ??
                        `${getSupplierBusinessName(item?.supplierId)}`}
                    </Text>
                    <IconButton
                      icon={<GrDownload />}
                      onClick={() => {
                        downloadInvoiceForOrder(item?.invoiceMediaId);
                      }}
                      aria-label="download"
                      size={"xs"}
                      bg={"none"}
                    />{" "}
                  </Box>

                  <UnorderedList
                    key={index}
                    listStyleType={"none"}
                    marginLeft={0}
                    marginBottom={"1em"}
                  >
                    {Object?.entries(item)?.map(
                      ([key, value]: [any, any], index) => {
                        return !excludedKeys.includes(key) ? (
                          <ListItem
                            display={"flex"}
                            key={index}
                            alignItems={"center"}
                          >
                            <>
                              {" "}
                              <Text fontSize={"sm"} minWidth={"90px"}>
                                {getTitleCaseFromCamelCase(key)}
                              </Text>
                              <Text fontSize={"sm"} marginRight={"5px"}>
                                :
                              </Text>{" "}
                            </>

                            <Tooltip label={`${value}`}>
                              <Text
                                fontSize={"sm"}
                                noOfLines={1}
                              >{`${value}`}</Text>
                            </Tooltip>
                            {copierKeys.includes(key) && (
                              <GarrazCopyText
                                text={value}
                                popOverMessage={`${key} Copied`}
                              ></GarrazCopyText>
                            )}
                          </ListItem>
                        ) : (
                          <React.Fragment key={index} />
                        );
                      }
                    )}
                  </UnorderedList>
                </ListItem>
              ) : (
                <React.Fragment key={index} />
              )
            )}
          </UnorderedList>
        </Box>
        <HStack justifyContent={"space-between"} mb="2">
          <Text color={"#4A5567"} fontSize={"lg"} fontWeight={"bold"}>
            Challan Info
          </Text>
        </HStack>
        {Challans.every((item) => !item?.challanNo) && (
          <Text mb={2} fontSize={"sm"}>
            No Challan Available
          </Text>
        )}
        <Box textColor={"#4A5567"}>
          <UnorderedList fontSize={"sm"}>
            {Challans?.map((item: any, index: number) =>
              item?.challanNo ? (
                <ListItem key={index}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    {" "}
                    <Text fontWeight={"bold"}>
                      {item?.title ??
                        `${getSupplierBusinessName(item?.supplierId)}`}
                    </Text>
                    <IconButton
                      icon={<GrDownload />}
                      onClick={() => {
                        downloadInvoiceForOrder(item?.challanMediaId);
                      }}
                      aria-label="download"
                      size={"xs"}
                      bg={"none"}
                    />{" "}
                  </Box>

                  <UnorderedList
                    key={index}
                    listStyleType={"none"}
                    marginLeft={0}
                    marginBottom={"1em"}
                  >
                    {Object?.entries(item)?.map(
                      ([key, value]: [any, any], index) => {
                        return !excludedKeys.includes(key) ? (
                          <ListItem
                            display={"flex"}
                            key={index}
                            alignItems={"center"}
                          >
                            <>
                              {" "}
                              <Text fontSize={"sm"} minWidth={"90px"}>
                                {getTitleCaseFromCamelCase(key)}
                              </Text>
                              <Text fontSize={"sm"} marginRight={"5px"}>
                                :
                              </Text>{" "}
                            </>

                            <Tooltip label={`${value}`}>
                              <Text
                                fontSize={"sm"}
                                noOfLines={1}
                              >{`${value}`}</Text>
                            </Tooltip>
                            {copierKeys.includes(key) && (
                              <GarrazCopyText
                                text={value}
                                popOverMessage={`${key} Copied`}
                              ></GarrazCopyText>
                            )}
                          </ListItem>
                        ) : (
                          <React.Fragment key={index} />
                        );
                      }
                    )}
                  </UnorderedList>
                </ListItem>
              ) : (
                <React.Fragment key={index} />
              )
            )}
          </UnorderedList>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default InvoicePopver;

import { create } from "zustand";
import { dateTodayApiFormat } from "../../../utils/dateResolvers";
import { createJSONStorage, persist } from "zustand/middleware";
import globalConstant from "../../../utils/constants";

export interface IFilter {
  filterObject: {
    limit: number;
    orderStatus: string;
    supplierOrderStatus: string;
    customerType: string;
    orderHandler: string;
    filterOrderNo: string;
    filterOrderNoCheckBoxValue: boolean;
    customerId: string;
    customerName: string;
    orderType: string;
    transactionType: string;
    invoiceStatus: string;
    startDate: string;
    endDate: string;
  };

  setFilterObject: (newFilterObject: any) => void;
  updateOrderHandlers: (orderHandlers: string[]) => void;
}

const useFiltersStore = create<IFilter>()(
  persist(
    (set, get) => ({
      filterObject: {
        limit: 100,
        orderStatus: `&orderStatus=CBO&orderStatus=CANCELLED&orderStatus=ACK&orderStatus=ALLOCATED&orderStatus=READY_TO_DISPATCH&orderStatus=NEW&orderStatus=RETURN_REQUESTED&orderStatus=CUSTOMER_RETURNED&orderStatus=CUSTOMER_RETURN_REJECTED&orderStatus=SUPPLIER_RETURNED&orderStatus=SUPPLIER_RETURN_REJECTED&orderStatus=DELIVERED&orderStatus=ACCEPTED&orderStatus=REJECTED&orderStatus=CUSTOMER_HOLD`,
        supplierOrderStatus: `&supplierOrderStatus=NEW&supplierOrderStatus=CBO&supplierOrderStatus=CANCELLED&supplierOrderStatus=ACK&supplierOrderStatus=READY&supplierOrderStatus=REVOKED&supplierOrderStatus=NONE&supplierOrderStatus=RETURN_REQUESTED&supplierOrderStatus=ACCEPTED&supplierOrderStatus=REJECTED`,
        customerType: "",
        filterOrderNoCheckBoxValue: false,
        filterOrderNo: "",
        orderHandler: "",
        customerId: "",
        customerName: "",
        orderType: "&orderType=REGULAR&orderType=RETURN&orderType=SUBSCRIPTION",
        transactionType:
          "&transactionType=Trading&transactionType=Facilitation&transactionType=NONE",
        invoiceStatus: "&invoiceStatus=PAID&invoiceStatus=UNPAID",
        startDate: dateTodayApiFormat,
        endDate: dateTodayApiFormat,
      },
      currentLoggedInUserId: "",
      setFilterObject: (newFilterObject: any) => {
        set({
          filterObject: newFilterObject,
        });
      },
      updateOrderHandlers: (orderHandlers: string[]) => {
        set((state) => ({
          filterObject: {
            ...state.filterObject,
            orderHandler: ["", ...orderHandlers].join("&orderHandlerId="),
          },
        }));
      },
    }),

    {
      name: globalConstant.ORDER_FILTER_SETTINGS,
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useFiltersStore;
